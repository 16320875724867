












import Vue from "vue";
import PreFooter from "@/components/PreFooter.vue";
import api from "@/api/api";
import { IPlan } from "@/types";

export default Vue.extend({
  components: {
    PreFooter,
  },
  data() {
    return {
    };
  },
  metaInfo(): { title: string } {
    return this.$route.meta;
  },
});
